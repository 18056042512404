<template>
  <div class="feedDetailWarp">
    <div class="feedback-wrap">
      <img class="sucessIcon" :src="img">
      <p> 提交成功</p>
      <div>感谢您的反馈</div>
      <van-button @click="sureBtnClick()">完成</van-button>
    </div>
  </div>

</template>

<script>
import img from '../../assets/img/mine/successIcon.png'
import common from "../../utils/common";
import {appAction, uwStatisticAction} from "../../utils/util";
import {FEEDBACK_FINISHED} from "../../utils/constantNum";
export default {
  name: "feedbackResult",
  data () {
    return {
      img,
    }
  },
  mounted() {
    uwStatisticAction('/feedbackResult','意见反馈结果')
  },
  methods: {
    sureBtnClick () {
      if (common.getItem('jumpFrom') != undefined ) {
        appAction(FEEDBACK_FINISHED)
      } else {
        this.$router.push('/main/mine')
      }

    }
  }
}
</script>

<style scoped lang="less">
.feedDetailWarp {
  margin-top: 32px;
  text-align: center;
  background: #F7F8F9;
  height: 100%;
  .feedback-wrap{
    background: #fff;
  }
  .sucessIcon {
    width: 64px;
    height: 64px;
  }
  p {
    font-size: 18px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin: 24px 0 8px 0;
  }
  div {
    font-size: 13px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
  .van-button__text{
    font-size: 0.36rem !important;
    color: #3F7C53;
  }
  .van-button {
    margin-top: 1.5rem;
    width: 5.6rem;
    height: 0.98rem;
    border: 0.01rem solid #3F7C53;
    background: #fff;
    margin-bottom: 0.68rem;
  }
}



</style>
